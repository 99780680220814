
const accountRoutes = [
    {
        path:'list',
        name:'account-list',
        component: () => import(/* webpackChunkName: "about" */ '@/views/account/list.vue')
    },
    {
        path:'add',
        name:'account-add',
        component: () => import(/* webpackChunkName: "about" */ '@/views/account/add.vue')
    },
    {
        path:'detail/:user_id',
        name:'account-detail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/account/info.vue')
    },
    {
        path:'edit/:user_id',
        name:'account-edit',
        component: () => import(/* webpackChunkName: "about" */ '@/views/account/edit.vue')
    },
    // {
    //     path:'del',
    //     name:'account-del',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/account/del.vue')
    // },

]
export default accountRoutes
const dentLabRoutes = [
    {
        path:'list',
        name:'dentLab-list',
        component: () => import(/* webpackChunkName: "about" */ '@/views/dentlab/list.vue'),
        redirect: { name: 'dentLab-list-myFavorites' },
        children:[{
            path:'myFavorites',
            name:'dentLab-list-myFavorites',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentlab/tables/myFavorites.vue')
        },{
            path:'black',
            name:'dentLab-list-black',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentlab/tables/black.vue')
        },{
            path:'search',
            name:'dentLab-list-search',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentlab/tables/search.vue')
        }]
    },
    {
        path:'info/:dentlab_id',
        name:'dentLab-info',
        component: () => import(/* webpackChunkName: "about" */ '@/views/dentlab/info.vue')
    },
]
export default dentLabRoutes
class Users {
    constructor(apiService = null) {
        this.obj_name = 'users';
        this.apiService = apiService; // 將 ApiService 實例儲存為屬性
    }

    // 共用的 API 呼叫方法
    async callApi(method, endpoint, parameter = {}, progressEvent = null) {
        const apiConfig = {
            url: `${this.apiService.url}${this.obj_name}/${endpoint}`,
            parameter,
        };

        return new Promise((resolve) => {
            if (method === 'get') {
                apiConfig.url += `?${this.apiService.getMethodEncryp(parameter)}`;
                this.apiService.getApi({
                    url: apiConfig.url,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'post') {
                this.apiService.postApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'put') {
                this.apiService.putApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            }
        });
    }

    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    // API 方法
    /**
     * 取得等待邀請的帳號列表
     * 
     * @async
     * @param {Object} parameter - 查詢參數
     * @param {number} parameter.com_id - 公司 ID
     * @param {number} parameter.com_type - 公司類型
     * @param {number} parameter.page_size - 每頁筆數
     * @param {number} parameter.st_page - 起始頁
     * @param {number} parameter.end_page - 結束頁
     * @returns {Promise<Object>} 回傳包含結果的物件
     * @returns {number} return.code - 回覆結果碼
     * @returns {string} return.message - 回覆訊息
     * @returns {Object} return.data - 回覆資料
        * @returns {Array} return.data.list - 資料列表
        * @returns {number} return.data.total_count - 總資料筆數
     */
    async getWaitInviteAccByCom(parameter) {
        return this.callApi('get', 'getWaitInviteAccByCom', parameter);
    }

    /**
     * 取得等待邀請的帳號列表
     * 
     * @async
     * @param {Object} parameter - 查詢參數
     * @param {number} parameter.role_id - 角色id
     * @param {number} parameter.get_type - 0:取得全部公司,1:取得所屬公司
     * @param {string} parameter.keyword - 關鍵字
     * @param {number} parameter.com_type - 公司類型
     * @param {number} parameter.page_size - 每頁筆數
     * @param {number} parameter.st_page - 起始頁
     * @param {number} parameter.end_page - 結束頁
     * @returns {Promise<Object>} 回傳包含結果的物件
     * @returns {number} return.code - 回覆結果碼
     * @returns {string} return.message - 回覆訊息
     * @returns {Object} return.data - 回覆資料
        * @returns {Array} return.data.list - 資料列表
        * @returns {number} return.data.total_count - 總資料筆數
     */
    async getAllAccByRoleAndKey(parameter) {
        return this.callApi('get', 'getAllAccByRoleAndKey', parameter);
    }

    /**
     * 使用者 - 新增邀請
     * @async
     * @param {Object} parameter - 查詢參數
     * @param {string} parameter.email - 邀請的email
     * @param {number} parameter.role_id - 邀請的email
     * @returns {Promise<Object>} 回傳包含結果的物件
     * @returns {number} return.code - 回覆結果碼
     * @returns {string} return.message - 回覆訊息
     * @returns {string} return.data - 發送邀請email
     */
    async addInvite(parameter,progressEvent=null) {
        return this.callApi('put', 'addInvite', parameter,progressEvent);
    }

    async getAccById(parameter){
        return this.callApi('get', 'getAccById', parameter);
    }

    async getComById(parameter){
        return this.callApi('get', 'getComById', parameter);
    }

    async updAcc(parameter,progressEvent=null) {
        return this.callApi('put', 'updAcc', parameter,progressEvent);
    }

    async updCom(parameter,progressEvent=null) {
        return this.callApi('put', 'updCom', parameter,progressEvent);
    }
}

export default Users;

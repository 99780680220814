const stores = {
    state () {
        return {
            loginStatus:true,
            loadingStatus:false,
            progressStatue:false,
            progressValue:0,
            actionResult:null,
            progress:-1,
            userData:null,
            tableDefaultValue:null
        }
    },
    mutations: {
        setTableDefaultValue(state,data){
            state.tableDefaultValue = data
        },
        initTableDefaultValue(state){
            state.tableDefaultValue = null  
        },
        setLoadingStatus(state, status) {
            state.loadingStatus = status;
        },
        setProgress(state, value) {
            state.progress = value;
        },
        setUserinfo (state,data) {
            state.userData = data.userData
            localStorage.setItem('userInfo',JSON.stringify(state.userData))
        },
        userLogOut(state){
            console.log('userLogOut')
            state.userData = null
            localStorage.clear()
        }
    }
}
export default stores
const dentureOrderRoutes = [
    {
        path:'list',
        name:'denture-order-list',
        redirect: { name: 'denture-order-list-waitConfirm' },
        component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/list.vue'),
        children:[{
            path:'waitConfirm',
            name:'denture-order-list-waitConfirm',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/waitConfirm/waitConfirm.vue')
        },{
            path:'processing',
            name:'denture-order-list-processing',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/processing.vue')
        },{
            path:'close',
            name:'denture-order-list-close',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/close.vue')
        },{
            path:'search',
            name:'denture-order-list-search',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/search.vue')
        }]
    },
    {
        path:'add',
        name:'add-denture-order',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/add/add.vue'),
        children:[{
            path:'removable',
            name:'add-denture-order-add-removable',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/add/addRemovable.vue')
        },{
            path:'fixed',
            name:'add-denture-order-add-fixed',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/add/addFixed.vue')
        }]
    },
    {
        path:'detail/:order_sn',
        name:'denture-order-info',
        component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/index.vue'),
        redirect: { name: 'denture-order-info-form' },
        children:[{
            path:'form',
            name:'denture-order-info-form',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/form.vue')
        },{
            path:'setting',
            name:'denture-order-info-setting',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/setting.vue')
        },{
            path:'history',
            name:'denture-order-info-history',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/history.vue')
        }]

    },
    // {
    //     path:'edit',
    //     name:'edit-denture-order',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/edit/edit.vue')
    // },
    // {
    //     path:'detail',
    //     name:'denture-order-info',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/info.vue')
    // },
    // {
    //     path:'denture-order-con',
    //     name:'denture-order-con',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/con.vue')
    // },
    // {
    //     path:'edit-denture-order',
    //     name:'edit-denture-order',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/edit/edit.vue')
    // },
    // //下列為Demo用
    // //選擇技工所
    // {
    //     path:'denture-order-select-dentlab',
    //     name:'denture-order-select-dentlab',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/selectDentlab.vue')
    // },
    // //開放媒合設定
    // {
    //     path:'denture-order-match-dentlab',
    //     name:'denture-order-match-dentlab',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/matchDentlab.vue')
    // },
    // //寄送實體齒模 Send solid tooth mold
    // {
    //     path:'denture-order-send-tooth-modal',
    //     name:'denture-order-send-tooth-modal',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/dentureOrder/detail/sendToothModal.vue')
    // },

]
export default dentureOrderRoutes
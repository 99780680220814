
const companyRoutes = [
    {
        path:'info',
        name:'company-info',
        component: () => import(/* webpackChunkName: "about" */ '@/views/company/info.vue')
    },
    {
        path:'edit',
        name:'company-edit',
        component: () => import(/* webpackChunkName: "about" */ '@/views/company/edit.vue')
    },
    {
        path:'del',
        name:'company-del',
        component: () => import(/* webpackChunkName: "about" */ '@/views/company/del.vue')
    },

]
export default companyRoutes
import $ from "jquery"
import CryptoJS from "crypto-js";

import Users from '@/assets/js/apiObj/users'
import DentLab from '@/assets/js/apiObj/dentLab'
import Company from '@/assets/js/apiObj/company'
import Clinic from '@/assets/js/apiObj/clinic'
import Order from '@/assets/js/apiObj/order'
class ApiService {
    //物件建立時產生
    constructor(){
        this.url = process.env.VUE_APP_API_URL
        this.key = ''
        this.header = {}
        this.isDecryption = false

        this.users = new Users(this)
        this.dentLab = new DentLab(this)
        this.company = new Company(this)
        this.clinic = new Clinic(this)
        this.order = new Order(this)
    }
    init(data){ //初始化header
        this.header = {
            token:data.token
        }
        this.key = this.getUserToken(data.token)
    }
    //user Token 處理成32長度
    getUserToken(token){
        console.log('getUserToken token->',token)
        let hToken = token.substr(0,16)
        console.log('getUserToken hToken ['+hToken.length+']->',hToken)
        let fToken = token.substr(token.length-16,token.length)
        console.log('getUserToken hToken ['+fToken.length+']->',fToken)
        return hToken+fToken
    }
    //解密
    AES_ECB_DECRYPT(token) {
        var decrypt = CryptoJS.AES.decrypt(
            token, 
            CryptoJS.enc.Utf8.parse(this.key),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return decrypt.toString( CryptoJS.enc.Utf8 )
    }
    //資料加密
    AES_ECB_ENCRYPT(parameter){
        console.log('AES_ECB_ENCRYPT = ',this.header.token)
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(parameter)), CryptoJS.enc.Utf8.parse(this.key), { 
            iv:CryptoJS.enc.Utf8.parse(this.iv),
            mode: CryptoJS.mode.ECB, 
            padding: CryptoJS.pad.Pkcs7
        }); 
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    }
    //api請求body資料加密
    apiDataEncrypt(parameter){
        if ( this.isDecryption ){ //需要加密
            parameter = {
                data:this.AES_ECB_ENCRYPT(parameter)
            }
            parameter = JSON.stringify(parameter)
        }else{ //資料不需要加密
            parameter = JSON.stringify(parameter)
        }
        return parameter
    }
    //api回應資料解密
    apiDataDecrypt(parameter){
        if ( this.isDecryption ){ //需要解密
            parameter = JSON.parse( this.AES_ECB_DECRYPT(parameter.data) )
        }
        return parameter
    }
    //處理login&註冊時產生token
    userInputEncrypt(){
        let token = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex)
        return token
    }
    //get加密
    getMethodEncryp(parameter){
        if ( this.isDecryption ){ //需要加密
            parameter = {
                data:this.AES_ECB_ENCRYPT(parameter)
            }
            parameter = "data="+parameter
        }else{ //資料不需要加密
            // parameter = JSON.stringify(parameter)
            let url = ''
            Object.keys(parameter).forEach((key) => {
                url = url+key+'='+parameter[key]+'&'
            });
            parameter =  url.substring(0, url.length - 1);
        }
        return parameter
    }
    //執行POST
    postApi(data){ 
        let self = this
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'POST',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            contentType : 'application/json; charset=utf-8', // 要送到server的資料型態
            data: self.apiDataEncrypt(data.parameter),
            success: function(res){
                console.log(res)
                // 成功以後會執行這個方法
                res = self.apiDataDecrypt(res)
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            xhr: function(){
                var xhr = new window.XMLHttpRequest(); // 建立xhr(XMLHttpRequest)物件
                xhr.upload.addEventListener("progress", function(progressEvent){ // 監聽ProgressEvent
                    if (progressEvent.lengthComputable) {
                        var percentComplete = progressEvent.loaded / progressEvent.total;
                        var percentVal = Math.round(percentComplete*100);
                        if ( data.progress ){
                            console.log("上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total); 
                            data.progress({
                                mag:"上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total,
                                loaded:progressEvent.loaded,
                                total:progressEvent.total,
                                percentComplete:percentComplete,
                                percentVal:percentVal
                            })
                        }   
                    }
                },false);
                return xhr; // 注意必須將xhr(XMLHttpRequest)物件回傳
            },
            error: function(){
                // alert('error[500] : serveer error')
                data.success({code:500})
            }
        })
    }
    //執行PUT
    putApi(data){
        let self = this
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'PUT',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            contentType : 'application/json; charset=utf-8', // 要送到server的資料型態
            data: self.apiDataEncrypt(data.parameter),
            success: function(res){
                console.log(res)
                // 成功以後會執行這個方法
                res = self.apiDataDecrypt(res)
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            xhr: function(){
                var xhr = new window.XMLHttpRequest(); // 建立xhr(XMLHttpRequest)物件
                xhr.upload.addEventListener("progress", function(progressEvent){ // 監聽ProgressEvent
                    if (progressEvent.lengthComputable) {
                        var percentComplete = progressEvent.loaded / progressEvent.total;
                        var percentVal = Math.round(percentComplete*100);
                        if ( data.progress ){
                            console.log("上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total); 
                            data.progress({
                                mag:"上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total,
                                loaded:progressEvent.loaded,
                                total:progressEvent.total,
                                percentComplete:percentComplete,
                                percentVal:percentVal
                            })
                        }   
                    }
                },false);
                return xhr; // 注意必須將xhr(XMLHttpRequest)物件回傳
            },
            error: function(){
                // alert('error[500] : server error')
                data.success({code:500})
            }
        })
    }
    //執行PUT
    delApi(data){
        let self = this
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'DELETE',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            contentType : 'application/json; charset=utf-8', // 要送到server的資料型態
            data: self.apiDataEncrypt(data.parameter),
            success: function(res){
                console.log(res)
                // 成功以後會執行這個方法
                res = self.apiDataDecrypt(res)
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            xhr: function(){
                var xhr = new window.XMLHttpRequest(); // 建立xhr(XMLHttpRequest)物件
                xhr.upload.addEventListener("progress", function(progressEvent){ // 監聽ProgressEvent
                    if (progressEvent.lengthComputable) {
                        var percentComplete = progressEvent.loaded / progressEvent.total;
                        var percentVal = Math.round(percentComplete*100);
                        if ( data.progress ){
                            console.log("上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total); 
                            data.progress({
                                mag:"上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total,
                                loaded:progressEvent.loaded,
                                total:progressEvent.total,
                                percentComplete:percentComplete,
                                percentVal:percentVal
                            })
                        }   
                    }
                },false);
                return xhr; // 注意必須將xhr(XMLHttpRequest)物件回傳
            },
            error: function(){
                // alert('error[500] : server error')
                data.success({code:500})
            }
        })
    }
    //執行GET
    getApi(data){
        // {url,parameter,callback}
        let self = this
        if (data.parameter){
            data.url = data.url+"?"+this.getMethodEncryp(data.parameter)
        }
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'GET',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            success: function(res){
                // 成功以後會執行這個方法
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            error: function(){
                // alert('error[500] : serveer error')
                data.success({code:500})
            }
        })
    }

    /*
    api區
    */
    //登入
    login(parameter,callback){
        this.header.token = this.userInputEncrypt()
        this.key = this.header.token
        //處理密碼加密
        var hash = CryptoJS.MD5(parameter.pwd);
        parameter.pwd = hash.toString()
        //呼叫post方法
        this.postApi({
            url:this.url+"users/login",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //檢查掛號系統是否存在
    getHisByToken(parameter,callback){
        //呼叫post方法
        this.getApi({
            url:this.url+"his/getHisByToken",
            parameter:parameter,
            success:function(res){
                //(0:success,-100:code error,-101:req data empty,-102:sql error,-2:email exisit)
                callback(res)
            }
        })
    }
    //
    getAllRoleByComType(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"users/getAllRoleByComType",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //取得帳號列表資料 getAllAccByRoleAndKey
    async getAllAccByRoleAndKey(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"users/getAllAccByRoleAndKey",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
        
    }

    //使用者相關API
    //使用者 - 取得帳號資料
    getAccById(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"users/getAccById",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //使用者 - 取得公司資料
    getComById(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"users/getComById",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //使用者 - 更新公司
    updCom(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"users/updCom",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //使用者 - 新增邀請
    addInvite(parameter,callback){
        //呼叫get方法
        this.putApi({
            url:this.url+"users/addInvite",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //
    updAcc(parameter,callback){
        //呼叫get方法
        this.putApi({
            url:this.url+"users/updAcc",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //診所 - 取得全部病患列表
    getAllPatientByClinicId(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"clinic/getAllPatientByClinicId",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //診所 - 取得全部病患列表bySnName
    async getAllPatientByClinicIdAndSnAndName(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"clinic/getAllPatientByClinicIdAndSnAndName",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
            
        })
        
    }
    //訂單 - 新增訂單
    addOrder(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/addOrder",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單-更新訂單
    updOrder(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/updOrder",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //訂單 - 取得診所待確認訂單
    async geClinicWaitlOrder(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"order/geClinicWaitlOrder",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
            
        })
    }
    //訂單 - 取得診所未建立完成訂單
    async geClinicNotFinishOrder(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"order/geClinicNotFinishOrder",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
        
    }
    //訂單 - 取得技工所待診所確認訂單
    async geClinicWaitConfirmOrder(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"order/geClinicWaitConfirmOrder",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
    }
    //訂單 - 取得診所進行中訂單
    async getAllOrderByClinicWithOrderAndPatient(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"order/getAllOrderByClinicWithOrderAndPatient",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
    }
    //訂單 - 取得診所已結束訂單
    getAllStatusOrderByClinicWithOrderAndPatient(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getAllStatusOrderByClinicWithOrderAndPatient",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //訂單 - 取得訂單資訊
    getOrderInfo(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getOrderInfo",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //訂單 - 取得假牙資訊
    getOrderDenture(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getOrderDenture",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    
    //訂單 - 訂單媒合技工所
    addAssignMatch(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/addAssignMatch",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 取得訂單歷史
    getOrderHistory(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getOrderHistory",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 更新訂單寄送資料
    updOrderSend(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/updClinicOrderSend",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //訂單 - 更新媒合時間
    updOrderMatchTime(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/updOrderMatchTime",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //
    updOrderMatch(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/updOrderMatch",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 更新訂單狀態
    updOrderStatus(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"order/updOrderStatus",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //技工所 - 取得技工所byName
    getAllDentLabByName(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"dentlab/getAllDentLabByName",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //技工所 - 取得類別技工所byName
    async getAllDentLabByNameAndStatus(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"dentlab/getAllDentLabByNameAndStatus",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
    }

    //技工所 - 取得技工所
    getDentLab(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"dentlab/getDentLab",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //診所 - 取得病患資料
    getPatient(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"clinic/getPatient",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //診所 - 更新病患資料
    updPatient(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"clinic/updPatient",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //診所 - 新增病患資料
    addPatient(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"clinic/addPatient",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //公司 - 新增黑名單
    addBackList(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"company/addBlackList",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //公司 - 新增我的最愛
    addFavList(parameter,callback){
        //呼叫put方法
        this.putApi({
            url:this.url+"company/addFavList",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //公司 - 新增我的最愛
    delBlackList(parameter,callback){
        //呼叫put方法
        this.delApi({
            url:this.url+"company/delBlackList",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //公司 - 新增我的最愛
    delFavList(parameter,callback){
        //呼叫put方法
        this.delApi({
            url:this.url+"company/delFavList",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 取得媒合資訊
    getOrderMatchInfo(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getOrderMatchInfo",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 取得已接受技工所
    getAllWaitMatchDentlab(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getAllWaitMatchDentlab",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //
    getOrderDentlab(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getOrderDentlab",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }

    //使用者 - 取得待處理帳號
    async getWaitInviteAccByCom(parameter){
        return new Promise((resolve, reject) => {
            //呼叫get方法
            this.getApi({
                url:this.url+"users/getWaitInviteAccByCom",
                parameter:parameter,
                success:function(res){
                    resolve(res)
                },
                error:function(err){
                    reject(err)
                }
            })
        })
    }

    //訂單 - 取得假牙設計資訊
    getDentlabStyleInfo(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getDentlabStyleInfo",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    //訂單 - 取得技工所訂單寄送資訊
    getDentlabOrderSendInfo(parameter,callback){
        //呼叫get方法
        this.getApi({
            url:this.url+"order/getDentlabOrderSendInfo",
            parameter:parameter,
            success:function(res){
                callback(res)
            }
        })
    }
    

}
export default ApiService
import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 */
function loadLocaleMessages() {
  const locales = require.context('@/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// 取得可用語系
const messages = loadLocaleMessages()
const availableLocales = Object.keys(messages)

// 取得已存儲的語系（如果有）
const savedLocale = localStorage.getItem('user-locale')

// 取得瀏覽器預設語系，例如 "zh-TW"
const browserLocale = navigator.language || navigator.userLanguage
console.log(`browserLocale=`,browserLocale)
// 選擇語系的邏輯：優先使用 `localStorage`，否則使用瀏覽器語系，最後回退到預設語系
const locale = availableLocales.includes(savedLocale)
  ? savedLocale
  : availableLocales.includes(browserLocale)
  ? browserLocale
  : process.env.VUE_APP_I18N_LOCALE || 'en'
  console.log(`availableLocales=` , availableLocales)
console.log(`locale=` , locale)

const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages
})

// 切換語系函數
export function setLocale(newLocale) {
  if (availableLocales.includes(newLocale)) {
    i18n.global.locale.value = newLocale
    localStorage.setItem('user-locale', newLocale) // 儲存語系到 localStorage
  }
}

export default i18n

<template>    
    <div v-if="$store.state.loadingStatus"
        class="full-screen-bg" id="full-screen-loading">
        <div class="spinner-border text-dark me-2 mb-4" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-if="$store.state.progressStatue" 
            class="progress-view">
            <div class="progress">
                <div class="progress-bar" role="progressbar" 
                    :style="{'width': $store.state.progressValue + '%'}" 
                    :aria-valuenow="$store.state.progressValue" aria-valuemin="0" aria-valuemax="100"
                >{{ $store.state.progressValue }}%
                </div>
            </div>
        </div>
        
    </div>    
</template>
<script>
export default {
name:'fullScreenLoadingView',
data(){
    return {
        
    }
},
watch:{
    '$store.state.loadingStatus'(){
        // console.log('$store.state.loadingStatus test')
    }
},
components: {
    
},
methods: {
   
},
async mounted(){
    
    
},
async created(){
    
}
};
</script>
<style scoped>
.full-screen-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    z-index: 1080;
}
.text-dark {
    --bs-text-opacity: 1;
    color: rgba(16, 56, 107), var(--bs-text-opacity) !important;
}
.progress-view{
    width: 60dvw;
    min-width:372px;
}
</style>
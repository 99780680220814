class Company {
    constructor(apiService = null) {
        this.obj_name = 'company';
        this.apiService = apiService; // 將 ApiService 實例儲存為屬性
    }

    // 共用的 API 呼叫方法
    async callApi(method, endpoint, parameter = {}, progressEvent = null) {
        const apiConfig = {
            url: `${this.apiService.url}${this.obj_name}/${endpoint}`,
            parameter,
        };

        return new Promise((resolve) => {
            if (method === 'get') {
                apiConfig.url += `?${this.apiService.getMethodEncryp(parameter)}`;
                this.apiService.getApi({
                    url: apiConfig.url,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'post') {
                this.apiService.postApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'put') {
                this.apiService.putApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'delete') {
                this.apiService.delApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                })
            }
        });
    }

    // API 方法
    /**
     * 取得等待邀請的帳號列表
     * 
     
     */
    //技工所 - 取得類別技工所byName
    async addFavList(parameter,progressEvent=null){
        return this.callApi('put', 'addFavList', parameter,progressEvent);
    }

    async addBlackList(parameter,progressEvent=null){
        return this.callApi('put', 'addBlackList', parameter,progressEvent);
    }

    async delFavList(parameter){
        return this.callApi('delete', 'delFavList', parameter);
    }

    async delBlackList(parameter){
        return this.callApi('delete', 'delBlackList', parameter);
    }

}

export default Company;

import i18n from '@/i18n'
class Parameters {
    constructor(){
    }
    //正式使用
    verify_status = [
        { id:1 , name:'Enable' },
        { id:2 , name:'Disable' }, 
        { id:3 , name:'Wait enable'  },
        { id:4 , name:'Timeout' },
    ]
    findVerifyStatus(id){
        const status = this.verify_status.find(item=>item.id===id)
        return status ? status : null
    }

    //正式使用
    invite_status = [
        { id:1 , name:'已被邀請' },
        { id:2 , name:'已逾時' }, 
        { id:3 , name:'等待邀請中'  },
        { id:4 , name:'已完成' },
        { id:5 , name:'自行註冊' } ,
    ]
    findInviteStatus(id){
        const status = this.invite_status.find(item=>item.id===id)
        return status ? status : null
    }

    //
    company_status = [
        { id:1 , name: 'Enable' },
        { id:2 , name: 'Disable' },
    ]
    findCompanyStatus(id){
        const status = this.company_status.find(item=>item.id===id)
        return status ? status : null
    }

    company_type = [
        { id:1 , name: 'MTX-DCP' },
        { id:2 , name: 'Third-party platforms' },
        { id:3 , name: 'simple-dental-clinic' },
        { id:4 , name: 'simple-dental-laboratory' },
    ]
    findCompanyType(id){
        const status = this.company_type.find(item=>item.id===id)
        return status ? status : null
    }
    
}
export default Parameters